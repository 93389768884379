<template>
  <div class="query-container">
    <div class="lot-container">
      <label for="lot-choice">选择彩种</label>
      <div class="select-container">
        <b-select
          v-model="selLot"
          name="lots"
          :options="queryLot"
          id="lot-choice">
        </b-select>
      </div>
      <!-- <p>lot: {{ selLot }}</p> -->
    </div>
    <div class="date-container">
      <label for="date-choice">选择日期</label>
      <div class="picker-container">
        <div class="date-choice-value" @click="changeDate">{{curDate}}</div>
        <div class="date-choice-icon" @click="changeDate"><b-icon icon="calendar4-event"></b-icon></div>
        <b-form-datepicker
          id="date-choice"
          v-model="curDate"
          today-button
          label-today-button="今天"
          label-help=""
          hide-header
          class="date-choice">
        </b-form-datepicker>
      </div>
      <!-- <input
        v-model="curDate"
        ref="dateChoice"
        type="date"
        id="date-choice"
        name="date-choice" /> -->
      <!-- <p>selDate: {{ selDate }}</p> -->
    </div>
    <div class="quick-date-container">
      <button
        @click="handleQuickDate(0)"
        :class="dayIsActive(0) ? 'active' : ''">
        今天
      </button>
      <button
        @click="handleQuickDate(-1)"
        :class="dayIsActive(-1) ? 'active' : ''">
        昨天
      </button>
      <button
        @click="handleQuickDate(-2)"
        :class="dayIsActive(-2) ? 'active' : ''">
        前天
      </button>
    </div>
  </div>
</template>

<script>
import { allLots } from '@/constants/lots'
// import { getYear, getMonth, getDate } from '@/utils/time'
import { goPage } from '@/utils/route'
import { getDiffDay, dateFormat } from '@/utils/time'
import moment from 'moment'

export default {
  props: {
    tab: {
      type: String,
      required: true
    },
    selDate: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      selLot: this.initSelLot()
      // selDate: this.initSelDate()
    }
  },
  computed: {
    queryLot: {
      get () {
        return (
          allLots
            .filter(item => item.id === this.tab)[0]
            .children
            .map( (lot) => {
              var obj = {}
              obj.value = lot.id
              obj.text = lot.name
              return obj
            })
        )
      }
    },
    curDate: {
      get () {
        // return dateFormat('yyyy-MM-dd', this.selDate)
        return moment(this.selDate).format('YYYY-MM-DD')
      },
      set (str) {
        // let setDate = dateFormat('yyyy-MM-dd', str)
        let setDate = moment(str).format('YYYY-MM-DD')
        this.$emit('handleChangeDate', setDate)
      }
    }
  },
  methods: {
    initSelLot () {
      if (this.$route.query.id) {
        return this.$route.query.id
      }
      return (
        allLots
          .filter(item => item.id === this.tab)[0]
          .children[0].id
      )
    },
    handleQuickDate (diffDay) {
      this.curDate = this.getQuickDate(diffDay)
    },
    getQuickDate (diffDay) {
      const dayMS = 1000 * 24 * 60 * 60
      const yyyyMMdd = dateFormat('yyyy-MM-dd', getDiffDay(diffDay * dayMS))
      return yyyyMMdd
    },
    dayIsActive (day) {
      return this.getQuickDate(day) === this.curDate
    },
    changeDate () {
      document.querySelector('#date-choice').click()
    }
    // initSelDate () {
    //   const today = new Date()
    //   return `${getYear(today)}-${getMonth(today)}-${getDate(today)}`
    // }
  },
  updated () {
    if (this.tab === this.$route.query.tab) {
      if (this.selLot !== this.$route.query.id) {
        goPage({
          name: 'LotteryResult',
          query: {
            tab: this.tab,
            id: this.selLot
          }
        })
      }
    }
  },
  watch: {
    '$route.query.id' () {
      this.selLot = this.initSelLot()
    }
  }
}
</script>

<style lang="scss" scoped>
.query-container {
  display: flex;
  // justify-content: space-between;
  // align-items: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  .lot-container {
    display: flex;
    align-items: center;
    label {
      color: #000000;
    }
    .select-container {
      width: fit-content;
      height: fit-content;
      select {
        color: #828282;
        appearance:none;
      }
    }
    // .select-container::after {
    //   content: "";
    //   width: 10px;
    //   height: 10px;
    //   background-color: red;
    //   border-bottom: 1px solid red;
    //   border-right: 1px solid red;
    //   transform: rotate(6deg);
    // }
  }
  .date-container {
    display: flex;
    align-items: center;
    label {
      color: #000000;
    }
    .picker-container{
      position: relative;
      cursor: pointer;
      .date-choice-value {
        position: absolute;
        color: #828282;
        font-size: 14px;
        font-weight: 500;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        z-index: 2;
      }
      .date-choice-icon {
        position: absolute;
        font-size: 20px;
        color: #828282;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        z-index: 2;
      }
      .date-choice{
        border: 1px solid #BDBDBD;
      }
    }
    
  }
  .quick-date-container {
    display: flex;
    align-items: center;
    button {
      color: #005FDE;
      border: 1px solid #2F84ED;
      background-color: #FFFFFF;
    }
  }
}
input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 0.5;
  cursor: pointer;
}

input[type="date"]::-webkit-calendar-picker-indicator:hover {
  opacity: 0.8
}
::v-deep .date-choice{
  & > .btn {
    .b-icon.bi {
      color: transparent;
    }
  }
  & > label {
    opacity: 0;
  }
}
@media (max-width: 575.98px) {
  .query-container {
    padding: 15px 15px 20px;
    .lot-container {
      // gap: 20px;
      flex-direction: column;
      margin-right: 30px;
      label {
        width: 100%;
        font-size: 14px;
        line-height: 23px;
        text-align: left;
        margin-bottom: 10px;
      }
      .select-container {
        width: calc((100vw - 30px - 30px) / 2);
        select {
          width: 100%;
          height: 40px;
          font-weight: 500;
          font-size: 13px;
          border: 1px solid #BDBDBD;
          border-radius: 5px;
          padding: 8px 12px;
        }
      }
    }
    .date-container {
      // gap: 20px;
      flex-direction: column;
      margin-right: 0;
      label {
        width: 100%;
        font-size: 14px;
        line-height: 23px;
        text-align: left;
        margin-bottom: 10px;
      }
      .picker-container {
        width: calc((100vw - 30px - 30px) / 2);
        .date-choice{
          width: 100%;
          height: 40px!important;
          padding: 0;
          border-radius: 5px;
        }
        .date-choice-value {font-size: 13px;}
        .date-choice-icon {font-size: 16px;}
      }
      // input {
      //   width: calc((100vw - 30px - 30px) / 2);
      //   height: 40px;
      //   padding: 8px 12px 8px 10px;
      //   font-weight: 500;
      //   font-size: 14px;
      //   border: 1px solid #BDBDBD;
      //   border-radius: 5px;
      // }
    }
    .quick-date-container {
      display: none;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .query-container {
    padding: 15px 15px 20px;
    .lot-container {
      // gap: 20px;
      flex-direction: column;
      margin-right: 30px;
      label {
        width: 100%;
        font-size: 14px;
        line-height: 23px;
        text-align: left;
        margin-bottom: 10px;
      }
      .select-container {
        width: calc((100vw - 30px - 30px) / 2);
        select {
          width: 100%;
          height: 40px;
          font-weight: 500;
          font-size: 13px;
          border: 1px solid #BDBDBD;
          border-radius: 5px;
          padding: 8px 12px;
        }
      }
    }
    .date-container {
      // gap: 20px;
      flex-direction: column;
      margin-right: 0;
      label {
        width: 100%;
        font-size: 14px;
        line-height: 23px;
        text-align: left;
        margin-bottom: 10px;
      }
      .picker-container {
        width: calc((100vw - 30px - 30px) / 2);
        .date-choice{
          width: 100%;
          height: 40px!important;
          padding: 0;
          border-radius: 5px;
        }
        .date-choice-value {font-size: 13px;}
        .date-choice-icon {font-size: 16px;}
      }
      // input {
      //   width: calc((100vw - 30px - 30px) / 2);
      //   height: 40px;
      //   padding: 8px 12px 8px 10px;
      //   font-weight: 500;
      //   font-size: 14px;
      //   border: 1px solid #BDBDBD;
      //   border-radius: 5px;
      // }
    }
    .quick-date-container {
      display: none;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .query-container {
    .lot-container {
      // gap: 20px;
      margin-right: 20px;
      label {
        font-size: 16px;
        margin-right: 12px;
      }
      .select-container {
        width: 186px;
        select {
          width: 100%;
          height: 40px;
          font-weight: 500;
          font-size: 14px;
          border: 1px solid #BDBDBD;
          border-radius: 5px;
          padding: 8px 12px;
        }
      }
    }
    .date-container {
      // gap: 20px;
      margin-right: 10px;
      label {
        font-size: 16px;
        margin-right: 12px;
      }
      .picker-container {
        width: 186px;
        .date-choice{
          width: 100%;
          height: 40px!important;
          padding: 0;
          border-radius: 5px;
        }
      }
      // input {
      //   width: 186px;
      //   height: 40px;
      //   padding: 8px 12px 8px 10px;
      //   font-weight: 500;
      //   font-size: 14px;
      //   border: 1px solid #BDBDBD;
      //   border-radius: 5px;
      // }
    }
    .quick-date-container {
      // gap: 10px;
      button {
        font-size: 14px;
        padding: 8px 6px;
        border-radius: 5px;
        box-sizing: border-box;
        width: 45px;
        height: 40px;
        margin-right: 8px;
        &:last-child { margin-right: 0; }
      }
      button:focus {
        outline: none;
      }
      .active {
        background-color: #E0F7FF;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .query-container {
    .lot-container {
      // gap: 20px;
      margin-right: 40px;
      label {
        font-size: 18px;
        margin-right: 20px;
      }
      .select-container {
        width: 240px;
        select {
          width: 100%;
          height: 40px;
          font-weight: 500;
          font-size: 14px;
          border: 1px solid #BDBDBD;
          border-radius: 5px;
          padding: 8px 15px;
        }
      }
    }
    
    .date-container {
      // gap: 20px;
      margin-right: 15px;
      label {
        font-size: 18px;
        margin-right: 20px;
      }
      // input {
      //   width: 240px;
      //   height: 40px;
      //   padding: 8px 15px 8px 20px;
      //   font-weight: 500;
      //   font-size: 14px;
      //   border: 1px solid #BDBDBD;
      //   border-radius: 5px;
      // }
      .picker-container {
        width: 240px;
        .date-choice{
          width: 100%;
          height: 40px!important;
          padding: 0;
          border-radius: 5px;
        }
      }
    }
    .quick-date-container {
      // gap: 10px;
      button {
        font-size: 14px;
        padding: 8px 6px;
        border-radius: 5px;
        box-sizing: border-box;
        width: 60px;
        height: 40px;
        margin-right: 10px;
        &:last-child { margin-right: 0; }
      }
      button:focus {
        outline: none;
      }
      .active {
        background-color: #E0F7FF;
      }
    }
  }
}
@media (min-width: 1200px) {
  .query-container {
    .lot-container {
      margin-right: 50px;
      label {
        font-size: 18px;
        margin-right: 20px;
      }
      .select-container {
        width: 300px;
        select {
          width: 100%;
          height: 40px;
          font-weight: 500;
          font-size: 14px;
          border: 1px solid #BDBDBD;
          border-radius: 5px;
          padding: 8px 15px;
        }
      }
    }
    .date-container {
      margin-right: 15px;
      label {
        font-size: 18px;
        margin-right: 20px;
      }
      .picker-container {
        width: 300px;
        .date-choice{
          width: 100%;
          height: 40px!important;
          padding: 0;
          border-radius: 5px;
        }
      }
    }
    .quick-date-container {
      button {
        font-size: 14px;
        padding: 8px 6px;
        border-radius: 5px;
        box-sizing: border-box;
        width: 60px;
        height: 40px;
        margin-right: 10px;
        &:last-child{margin-right: 0;}
      }
      button:focus {
        outline: none;
      }
      .active {
        background-color: #E0F7FF;
      }
    }
  }
}
</style>
