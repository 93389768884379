<template>
  <div class="result-container">
    <!-- <div>id: {{ result }}</div> -->
    <div>
      <b-table
        :fixed="!isMobile"
        :sticky-header="isMobile"
        :items="result"
        :fields="title"
        show-empty
        :empty-text="showMsg"
        class="result-table"
        thead-class="result-table-header">
        <template #cell(opendate)="date">
          <div>{{isMobile ? getIssueTime(date.value) : date.value}}</div>
        </template>
        <template #cell(code)="codes">
          <div
            class="lot-num-container"
            :class="isTooMany(getLotNums(codes.value)) ? 'too-many' : ''">
            <div
              v-for="(num, idx) in getLotNums(codes.value)"
              :key="num + idx"
              class="lot-num">
              {{ num }}
            </div>
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { isMobile } from "@/utils/device"

export default {
  props: {
    result: {
      type: Array,
      required: true
    },
    showMsg: {
      type: String,
      default: 'Loading...'
    }
  },
  data () {
    return {
      title: [
        {key: 'opendate', label: '时间'},
        {key: 'issue', label: '期数'},
        {key: 'code', label: '开奖号码'}
        // '时间', '期数', '开奖号码'
      ]
    }
  },
  computed: {
    isMobile () {
      return isMobile()
    }
  },
  methods: {
    getIssueTime (date) {
      return date.split(' ')[1]
    },
    getLotNums (nums) {
      const generalNums = nums.split('+')[0]
      const retNums = generalNums.split(',')
      return retNums
    },
    isTooMany (nums) {
      return this.isMobile ? nums.length > 8 : nums.length > 12
    }
  }
}
</script>

<style lang="scss" scoped>
$border-style: 1px solid #A7A7A7;
.result-container {
  width: 100%;
}
::v-deep .result-table tbody tr:nth-child(even) { background-color: #F2F2F2;}
::v-deep .result-table {
  width: 100%;
  border-collapse: separate;
  border-top-left-radius: 5px;
  .result-table-header {
    background: linear-gradient(77.4deg, #0053FF 3.38%, #579FFF 91.23%);
    tr {
      height: 50px;
      th {
        font-weight: 500;
        font-size: 18px;
        text-align: center;
        color: #FAFAFA;
        border: none;
        &:first-child {
          border-top-left-radius: 5px;
        }
        &:last-child {
          border-top-right-radius: 5px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        min-height: 66px;
        font-size: 18px;
        border-left: $border-style;
        color: #4F4F4F;
        vertical-align: baseline;
        border-top: none;
        .lot-num {
          font-size: 20px;
          width: 36px;
          height: 36px;
        }
        &:last-child{border-right: $border-style;}
      }
      .lot-num-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        // gap: 10px;
        padding: 10px 15px;
        .lot-num {
          background-color: #2D9CDB;
          border-radius: 100%;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 5px 10px 5px 0;
        }
      }
      .too-many {
        justify-content: flex-start;
      }
      &:last-child {
        td {
          border-bottom: $border-style;
          &:first-child {
            border-bottom-left-radius: 5px;
          }
          &:last-child {
            border-bottom-right-radius: 5px;
          }
        }
      }
    }
  }
  .b-table-sticky-header { // 手機尺寸才有header置頂效果
    margin-bottom: 10px;
    box-shadow: 0 -2px 3px rgba(0, 0, 0, 0.03);
  }
  .table td {cursor: default;}
  @media (max-width: 575.98px) {
    width: 100%;
    max-height: calc(100vh - 295px);
    border-collapse: separate;
    border-top-left-radius: 0;
    margin-bottom: 5px;
    .result-table-header {
      background: #FFF;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      tr {
        height: fit-content;
        th {
          font-size: 14px;
          color: #4F4F4F;
          line-height: 20px;
          padding: 10px auto;
          border: none;
          &:first-child {
            border-top-left-radius: 0;
          }
          &:last-child {
            border-top-right-radius: 0;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          min-width: 3em;
          min-height: 66px;
          font-size: 12px;
          border: none;
          color: #4F4F4F;
          vertical-align: baseline;
          padding: 7px;
          &:first-child {padding-left: 15px;}
          &:last-child {
            padding-right: 5px;
            border: none;
          }
        }
        .lot-num-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          // gap: 10px;
          padding: 0;
          .lot-num {
            font-size: 12px;
            width: 24px;
            height: 24px;
            line-height: 24px;
            margin: 5px 5px 5px 0;
          }
        }
        .too-many {
          justify-content: flex-start;
        }
        &:last-child {
          td {
            border-bottom: none;
            &:first-child {
              border-bottom-left-radius: 5px;
            }
            &:last-child {
              border-bottom-right-radius: 5px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 576px) and (max-width: 767.98px) {
    width: 100%;
    max-height: calc(100vh - 295px);
    border-collapse: separate;
    border-top-left-radius: 0;
    margin-bottom: 5px;
    .result-table-header {
      background: #FFF;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      tr {
        height: fit-content;
        th {
          font-size: 14px;
          color: #4F4F4F;
          line-height: 20px;
          padding: 10px auto;
          border: none;
          &:first-child {
            border-top-left-radius: 0;
          }
          &:last-child {
            border-top-right-radius: 0;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          min-height: 66px;
          font-size: 12px;
          border: none;
          color: #4F4F4F;
          vertical-align: baseline;
          padding: 7px;
          &:first-child {padding-left: 15px;}
          &:last-child {
            padding-right: 5px;
            border: none;
          }
        }
        .lot-num-container {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          // gap: 10px;
          padding: 0;
          .lot-num {
            font-size: 12px;
            width: 24px;
            height: 24px;
            line-height: 24px;
            margin: 5px 5px 5px 0;
          }
        }
        .too-many {
          justify-content: flex-start;
        }
        &:last-child {
          td {
            border-bottom: none;
            &:first-child {
              border-bottom-left-radius: 5px;
            }
            &:last-child {
              border-bottom-right-radius: 5px;
            }
          }
        }
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991.98px) {
    margin-bottom: 0;
    tbody {
      tr {
        td {
          font-size: 16px;
          vertical-align: middle;
        }
        .lot-num-container {
          padding: 0;
          .lot-num {
            font-size: 16px;
            width: 30px;
            height: 30px;
            line-height: 30px;
            margin: 8px 8px 8px 0;
          }
        }
      }
    }
  }
}
@media (max-width: 575.98px) {
  .result-container {
    margin-bottom: 0!important;
    padding-bottom: 5px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .result-container {
    margin-bottom: 0!important;
    padding-bottom: 8px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {}
@media (min-width: 992px) and (max-width: 1199.98px) {}
@media (min-width: 1200px) {}
</style>
