<template>
  <div
    v-if="totalPages > 1"
    class="pagination-container">
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      first-class="arrow-item"
      prev-class="arrow-item"
      next-class="arrow-item"
      last-class="arrow-item"
    ></b-pagination>
  </div>
</template>

<script>
export default {
  props: {
    curPage: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    totalPages: {
      type: Number,
      required: true
    }
  },
  computed: {
    currentPage: {
      get () {
        return this.curPage
      },
      set (page) {
        this.$emit('handleChangePage', page)
      }
    },
    totalRows: {
      get () {
        return this.totalPages * this.pageSize
      }
    },
    perPage: {
      get () {
        return this.pageSize
      }
    }
  }
}
</script>

<style lang="scss">
.pagination-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  .b-pagination {
    display: flex;
    .page-item {
      button {
        color: #212B36;
      }
      .page-link {
        font-weight: 500;
        font-size: 14px;
        padding: 0.46rem 0.75rem;
      }
    }
    .active {
      .page-link {
        background: linear-gradient(78.86deg, #0053FF 6.92%, #579FFF 91.5%) !important;
      }
    }
    .disabled {
      span {
        background-color: #919EAB !important;
        border-color: #919EAB !important;
        opacity: 0.5;
      }
    }
    .bv-d-xs-down-none {
      span {
        background-color: #ffffff !important;
      }
    }
    .arrow-item {
      span {
        color: #C4CDD5 !important;
      }
      button {
        color: #C4CDD5 !important;
      }
    }
  }
}

@media (max-width: 575.98px) {
  .pagination-container{
    width: 100%;
    margin-bottom: 0!important;
    padding: 5px 0 25px!important;
    text-align: center;
    .b-pagination {
      // gap: 10px;
      justify-content: center;
      li {
        margin-right: 10px;
        &:last-child {margin-right: 0;}
        span {
          border-radius: 5px;
        }
        button {
          border-radius: 5px;
        }
        .page-link {
          font-weight: 500;
          font-size: 13px;
          padding: 0.465rem 0.75rem;
        }
      }
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .pagination-container{
    width: 100%;
    margin-bottom: 0!important;
    padding: 8px 0 28px!important;
    text-align: center;
    .b-pagination {
      // gap: 10px;
      justify-content: center;
      li {
        margin-right: 10px;
        &:last-child {margin-right: 0;}
        span {
          border-radius: 5px;
        }
        button {
          border-radius: 5px;
        }
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .b-pagination {
    // gap: 10px;
    li {
      margin-right: 10px;
      &:last-child {margin-right: 0;}
      span {
        border-radius: 5px;
      }
      button {
        border-radius: 5px;
      }
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .b-pagination {
    // gap: 10px;
    li {
      margin-right: 10px;
      &:last-child {margin-right: 0;}
      span {
        border-radius: 5px;
      }
      button {
        border-radius: 5px;
      }
    }
  }
}
@media (min-width: 1200px) {
  .b-pagination {
    // gap: 10px;
    li {
      margin-right: 10px;
      &:last-child {margin-right: 0;}
      span {
        border-radius: 5px;
      }
      button {
        border-radius: 5px;
      }
    }
  }
}
</style>
