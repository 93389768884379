<template>
  <div class="content-container">
    <Query
      :tab="tab"
      :selDate="query.date"
      @handleChangeDate="handleChangeDate" />
    <Result 
      :result="result.data"
      :showMsg="showMsg" />
    <Pagination
      :curPage="query.page"
      :pageSize="query.pageSize"
      :totalPages="result.totalPages"
      @handleChangePage="handleChangePage" />
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { allLots } from '@/constants/lots'
import { getYear, getMonth, getDate } from '@/utils/time'
import { getLotWinNumHistory } from '@/api/client/lot'
import Query from './Query'
import Result from './Result'
import Pagination from './Pagination'

export default {
  components: {
    Query,
    Result,
    Pagination
  },
  data () {
    return {
      tab: 'template',
      query: {
        page: 1,
        pageSize: 20,
        code: null,
        date: null
      },
      result: {
        data: [],
        totalPages: 0
      },
      showMsg: '',
      message: {
        error: '无法取得数据，请稍后再试',
        loading: 'Loading...',
        empty: '目前尚无数据'
      }
      // id: null,
    }
  },
  computed: {
    content: {
      get () {
        const content = allLots
          .filter(item => item.id === this.tab)[0]
          .children

        return content
      }
    }
  },
  methods: {
    initTab () {
      if (this.$route.query.tab) {
        this.tab = this.$route.query.tab
      }
    },
    initLotId () {
      if (this.$route.query.id) {
        return this.$route.query.id
      }
      return (
        allLots
          .filter(item => item.id === this.tab)[0]
          .children[0].id
      )
    },
    initSelDate () {
      const today = new Date()
      return `${getYear(today)}-${getMonth(today)}-${getDate(today)}`
    },
    handleChangeLot () {
      this.initQuery()
      this.query.code = this.initLotId()
      this.updateResult()
      // this.$forceUpdate()
    },
    handleChangeDate (str) {
      this.initQuery()
      this.query.date = str
      this.updateResult()
    },
    initQuery () {
      this.query.page = 1
    },
    emptyResult () {
      this.result = {
        data: [],
        totalPages: 0
      }
    },
    updateResult () {
      this.showMsg = this.message.loading
      this.emptyResult()
      getLotWinNumHistory(this.query)
        .then(res => {
          if (res.status === resStatus.OK) {
            this.showMsg = ''
            this.result = res
            if (res.count == 0) this.showMsg = this.message.empty
          } else {
            this.showMsg = this.message.error
          }
        })
    },
    handleChangePage (page) {
      this.query.page = page
      this.updateResult()
    }
  },
  created () {
    this.initTab()
    // this.id = this.initLotId()
    this.query.code = this.initLotId()
    this.query.date = this.initSelDate()
    this.updateResult()
  },
  watch: {
    '$route.query.id' () {
      if (this.tab === this.$route.query.tab) {
        // if (this.id !== this.$route.query.id) {
        if (this.query.code !== this.$route.query.id) {
          // this.id = this.initLotId()
          this.handleChangeLot()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.content-container {
  // background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 575.98px) {
  .content-container {
    background-color: #F3F9FF;
    padding: 0;
    // gap: 35px;
    & > div {
      margin-bottom: 10px;
      background-color: #FFF;
    }
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .content-container {
    background-color: #F3F9FF;
    padding: 0;
    // gap: 35px;
    & > div {
      margin-bottom: 10px;
      background-color: #FFF;
    }
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .content-container {
    padding: 35px 30px 65px;
    // gap: 35px;
    & > div {
      margin-bottom: 30px;
      &:last-child { margin-bottom: 0;}
    }
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .content-container {
    padding: 35px 30px 65px;
    // gap: 35px;
    & > div {
      margin-bottom: 30px;
      &:last-child { margin-bottom: 0;}
    }
  }
}
@media (min-width: 1200px) {
  .content-container {
    padding: 40px 35px 65px;
    // gap: 35px;
    & > div {
      margin-bottom: 35px;
      &:last-child { margin-bottom: 0;}
    }
  }
}
</style>
